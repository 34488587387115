
import { Component, Mixins } from 'vue-property-decorator';
import { PageState } from '@/models/PageState';
import { DebounceMixin, LocalForageMixin, VuetifyMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import Dialog from '@/components/ui/Dialog.vue';
import { BASchoolModel } from '../../models/baSchool/BASchoolModel';
import { CSVUploadMixin } from '@/mixins/CSVUploadMixin';
import { baSchoolApi } from '@/api/BASchoolApi';

@Component({
	components: {
		CSVUploadDialog,
		Dialog,
		ExcelDataTable,
	}
})
export default class BASchoolsImport extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin, CSVUploadMixin, StringsMixin, BAIconsMixin) {
	pageState = new PageState("Ready");

	sampleBASchoolLogo = [
		{
			ipedsId: "123456",
			name: "University of College",
			logoUrl: "https://example.com/images/logo"
		},
	];
	parseBASchoolsLogo = (csvString: string): Partial<BASchoolModel>[] => {
		const rowJson = this.csv2json(csvString);
		const baSchoolsLogo = rowJson.map(row => {
			const baSchoolLogo = {
				ipedsId: row.ipedsId,
				name: row.name,
				logoUrl: row.logoUrl
			}
			return baSchoolLogo;
		});
		return baSchoolsLogo;
	};

	showUploadBASchoolsLogoDlg = false;
	showUploadPreview = false;

	unconfirmedBASchoolsLogo: Array<BASchoolModel> = [];
	get BASchoolsLogoRowPreview(): Array<any>{
		return this.unconfirmedBASchoolsLogo;
	}

	onBASchoolsLogoLoaded(data: Array<BASchoolModel>): void {
		this.unconfirmedBASchoolsLogo = data;
		this.showUploadPreview = true
	}
	startOver(): void{
		this.importingBASchoolsLogo = false;
		this.showUploadPreview = false;
		this.unconfirmedBASchoolsLogo = [];
	}

	importingBASchoolsLogo: boolean = false;
	get isImporting(): boolean {
		return this.importingBASchoolsLogo;
	}
	baSchoolsLogoUpdated: number = 0;
	BASchoolLogoUpdateError: string;
	async confirmBASchoolsLogo() {
		this.importingBASchoolsLogo = true;

		this.$emit('submit', {
			baSchoolsLogo: this.unconfirmedBASchoolsLogo,
		});

		this.showUploadPreview = false;
		// TODO: Add update notifications
		await baSchoolApi.updateBASchoolsLogo(this.unconfirmedBASchoolsLogo);
		this.$emit('updated')
		this.startOver();
	}
	abort: boolean = false;
	onAbortUpload() {
		this.abort = true;	
		this.startOver();
	}
	get BASchoolsLogoCount(): number{
		return this.unconfirmedBASchoolsLogo.length;
	}
	get BASchoolsLogosCount(): number {
		return this.unconfirmedBASchoolsLogo.filter(l => !!l.logoUrl && l.logoUrl.length > 0).length;
	}
}
