
import { Component, Mixins, Watch, PropSync, Prop } from 'vue-property-decorator';
import { VuetifyMixin } from '@/mixins/VuetifyMixin';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { organizationApi } from '@/api/OrganizationApi';
import { DebounceMixin } from '@/mixins';

@Component
export default class AdminOrganizationAutocomplete extends Mixins(DebounceMixin, VuetifyMixin){
	search: string = '';

	@PropSync('value') Value: OrganizationModel | null;
	@Prop({ default: "Organizations" }) label: string;
	@Prop({ default: "Start typing to search" }) placeholder: string;
	@Prop({ default: 8 }) limit: number;
	@Prop({ type: Boolean }) dense;
	organizationItems: OrganizationModel[] = [];

	searchLoading: boolean = false;
	@Watch('search') debounceSearchOrganizations(): void {
		this.searchLoading = true;
		this.debounceCallback('searchOrganizations', this.searchOrganizations.bind(this), 150);
	}

	async searchOrganizations(): Promise<void> {
		this.searchLoading = true;
		try {
			const organizationSearch = await organizationApi.queryAll({
				search: this.search,
				fields: [
					'name',
				]
			},{
				limitPerPage: this.limit,
			});
			this.organizationItems = organizationSearch.docs;
		} catch(e) {
			console.error('Failed to query organizations', e);
		}finally{
			this.searchLoading = false;
		}
	}
}
