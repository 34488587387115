
import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import AdminSchoolSearchModule from '@/store/admin/AdminSchoolSearch.store';
import { getModule } from 'vuex-module-decorators'
const schoolSearchStore = getModule(AdminSchoolSearchModule);
import Page from '@/views/Page.vue';
import Alert from '@/components/ui/Alert.vue';
import SurveySvg from '@/components/svg/SurveySvg.vue';
import MultiStepForm from '@/components/forms/MultiStepForm.vue';
import SchoolCreateSchoolInfo from '@/components/admin/schoolSearch/SchoolCreateSchoolInfo.vue';
import SchoolCreateSchoolStats from '@/components/admin/schoolSearch/SchoolCreateSchoolStats.vue';
import SchoolCreateSchoolCosts from '@/components/admin/schoolSearch/SchoolCreateSchoolCosts.vue';
import SchoolCreateSchoolTeam from '@/components/admin/schoolSearch/SchoolCreateSchoolTeam.vue';
import { SchoolCreateLocationInfo } from "@/components/admin/schoolSearch/SchoolCreateLocationInfo.vue";
import MultiStepNavItem from '@/components/forms/MultiStepNavItem.vue';
import { MultiStepFormMixin, BAIconsMixin } from '@/mixins';
import { SchoolModel,  LocationModel, SportDetailModel } from '@/models/school/SchoolModel';
import { schoolApi } from '@/api/SchoolApi';
import { SchoolSearchAdminDashboard } from '@/../types/constants/admin.routes';
import { notificationStore } from '@/store';
import { NotificationModel } from '@/models/notification/NotificationModel';

@Component({
	components: {
		Alert,
		MultiStepForm,
		MultiStepNavItem,
		Page,
		SurveySvg,
		SchoolCreateSchoolInfo,
		SchoolCreateSchoolStats,
		SchoolCreateSchoolCosts,
		SchoolCreateSchoolTeam,
		SchoolCreateLocationInfo
	},
})
export default class SchoolCreateForm extends Mixins(MultiStepFormMixin, BAIconsMixin){

	get StaticStepLabels(): string[]{
		return [
			'School Info',
			'School Stats',
			'School Costs',
		];
	}
	get LocationAndSportLabels(): string[] {
		const locationSportLabels = [];
		this.editSchool.locations.forEach(location => {
			locationSportLabels.push(location.name);
			location.sports.forEach(sport => {
				locationSportLabels.push(`${sport.name} (${sport.gender})`)
			})
		})
		return locationSportLabels;
	}
	
	getTeamStep(index: number): number{
		return (index + 1) + this.StaticStepLabels.length;
	}
	getTeamStepActive(index: number): boolean{
		return this.currentStep === this.getTeamStep(index);
	}

	get FormStepLabels(): string[]{
		return [
			...this.StaticStepLabels,
			...this.LocationAndSportLabels
		];
	}
	get IsEdit(): boolean{
		return this.editSchoolId !== undefined;
	}

	/**
	 * Returns an array with the position of where the labels are FormStepLabel array
	 */
	get LocationLabelIndexes(): number[] {
		if (this.Locations.length === 0) return [];

		const locationLabelIndex: number[] = [];
		
		let pos = this.StaticStepLabels.length;
		locationLabelIndex.push(pos);
		for (const location of this.Locations){
			pos += location.sports.length + 1
			locationLabelIndex.push(pos)
		}
		locationLabelIndex.pop()
		return locationLabelIndex;
	}

	/**
	 * Returns an array with positin of where the Sport label index position in FormStepLabel array
	 */
	get SportLabelIndexes(): { locationIndex: number, sportIndex: number, labelIndex: number }[]{
		// Check if the there 0 sports
		if (this.Locations.length === 0 || this.Locations.map(loc => loc.sports.length).reduce((prev, acc) => prev + acc) === 0) {
			return []
		}

		const sportLabelsIndexex: { locationIndex: number,  sportIndex: number,  labelIndex: number,  }[] = [];

		let pos = this.StaticStepLabels.length - 1;
		let locationIndex = 0;

		for (const location of this.Locations) {
			pos += 1;
			location.sports.forEach((_, index) => { pos += 1; sportLabelsIndexex.push({ sportIndex: index, locationIndex, labelIndex: pos}); })
			locationIndex += 1;
		}
		
		return sportLabelsIndexex;
	}

	@Prop({ default: undefined }) editSchoolId?: string;
	@Watch('editSchoolId') async checkEditSchool(): Promise<void>{
		if (this.IsEdit) {
			await this.setupEditSchool(this.editSchoolId);
		}else{
			this.resetForm();
		}
	}
	async mounted(): Promise<void>{
		this.checkEditSchool();
	}

	loadingSchool: boolean = false;
	editSchool: SchoolModel = new SchoolModel();
	originalEditSchool: SchoolModel = new SchoolModel();
	async setupEditSchool(id: string): Promise<void>{
		try{
		this.loadingSchool = true;
		this.editSchool = await schoolApi.findById(id);
		if( this.editSchool ) {
			this.originalEditSchool = this.editSchool.copy();
		}
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		} finally {
			this.loadingSchool = false;
		}
	}
	resetForm(): void{
		this.formValue = this.defaultForm();
	}

	formComplete: boolean = false;
	loading: boolean = false;
	submitting: boolean = false;
	get Loading(): boolean{
		return this.loading || this.submitting;
	}
	get Steps(): number{
		return this.FormStepLabels.length;
	}

	get Locations(): LocationModel[] {
		return this.editSchool.locations;
	}
	currentStep = 1;

	get CurrentStepValid(): boolean {
		if (!this.formValue[this.CurrentStepKey]) return true;
		return this.formValue[this.CurrentStepKey].valid;
	}

	defaultForm = (): Record<string, {valid: boolean}> => ({
		step1: {
			valid: false,
		},
		step2: {
			valid: true,
		},
		step3: {
			valid: true,
		}
	});
	formValue = this.defaultForm();

	addTeam(locationIndex: number): void{
		console.log('adding team')
		this.editSchool.locations[locationIndex].sports.push(new SportDetailModel())
		
	}

	addLocation(): void {
		console.log('adding location');
		this.editSchool.locations.push(new LocationModel())
		this.$nextTick(() => {
			this.currentStep = this.Steps;
		})
	}

	async save(): Promise<void>{
		const savedSchool = await schoolSearchStore.saveSchool({ school: this.editSchool });
		notificationStore.pushLocalNotification(new NotificationModel(`${this.editSchool.id} Saved`));
		this.editSchool = savedSchool;
		this.originalEditSchool = savedSchool;
		this.goToDashboard();
	}
	
	cancel(): void{
		this.goToDashboard();
	}

	goToDashboard(): void{
		this.$router.push({ name: SchoolSearchAdminDashboard });
	}
}
