
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { CSVUploadMixin } from "@/mixins/CSVUploadMixin";
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import { MindsetReportModel } from '@/models/mindset/MindsetReportModel';
import { MindsetReportEntry } from '@/../types/interfaces/MindsetReportFormValue';
import { ContactInfo } from '@/../types/interfaces';

@Component({
	components: {
		CSVUploadDialog,
	}
})
export default class MindsetImportDialog extends Mixins(VuetifyMixin, CSVUploadMixin, BAIconsMixin) {
	showUploadDialog = false;

	sampleCSVData = [{
		firstName: "Abby",
		lastName: "Andres",
		tapID: "732650",
		date: "2023-01-01",
		email: "abby101@bestathletes.co",
		dateOfBirth: "2006-02-27",
		position: "Forward", 
		school: "Waterloo SS", 
		schoolType: "High School",
		schoolLocation: "Canada",

		archetype: "Eagle",
		trafficLight: "Green+",

		overall: 61,
		mindsetFit: "N/A",
		
		mentalToughness: "5",
		composure: "55",
		confidence: "55",
		drive: "55",
		grit: "55",

		mentalExecution: "55",
		adaptability: "55",
		decisiveness: "55",
		focusSpeed: "55",

		coachability: "55",
		attentionToDetail: "55",
		conventionality: "55",
		growthMindset: "55",
		informationProcessing: "55",

		hittingMindset: "55",
		leadershipRating: "55",
		pressurePerformer: "55",
	}];

	parseMindsetReports(csv: string): MindsetReportEntry[] {
		const jsonEntries = this.csv2json(csv);
		const reports = jsonEntries.map( entry => {
			const mindsetReport: MindsetReportModel = {
				...entry as any,
			};
			const contact: ContactInfo = {
				firstName: mindsetReport.firstName,
				lastName: mindsetReport.lastName,
				email: mindsetReport.email
			};
			return {mindsetReport, contact};
		})
		return reports;
	}

	onReportsLoaded(entries: MindsetReportEntry[]) {
		const reports = entries.map(entry => {return entry.mindsetReport});
		this.$emit("submit", reports);
	}
}
