
import { Component,  Watch, Mixins } from 'vue-property-decorator';
import Page from '../Page.vue';
import { backendJobApi } from '@/api/BackendJobApi';
import { BackendQueue, BackendJob } from '@/../types/interfaces';
import { LocalForageMixin, BAIconsMixin } from '@/mixins';
import { formatDateMonthDay, getTime, getDifferenceString } from '@/helpers/date';
import { DataTableHeader } from 'vuetify';

@Component({
	components:{
		Page,
	}
})
export default class BackendJobs extends Mixins(LocalForageMixin, BAIconsMixin){
	formatDateMonthDay = formatDateMonthDay;
	getTime = getTime;

	getProcessTime(item: BackendJob): string{
		return getDifferenceString(new Date(item.processedOn), new Date(item.finishedOn), [ 'seconds', 'minutes' ] );
	}

	formatCode(data: Record<any,any>, indentation = 4): string{
		return JSON.stringify(data, null, indentation);
	}

	localForagePersistFields: Array<string | [string, any]> = [
		['selectedQueue', null],
	];

	headers: DataTableHeader<any>[] = [
		{
			text: 'ID',
			value: 'id',
		},
		{
			text: 'Name',
			value: 'data.eventName',
		},
		{
			text: 'Timestamp',
			value: 'timestamp',
		},
		{
			text: 'Processed',
			value: 'processedOn',
		},
		{
			text: 'Finished',
			value: 'finishedOn',
		},
		{ text: '', value: 'data-table-expand' },
	]

	selectedQueue: BackendQueue | null = null;
	jobsLoading: boolean = false;
	jobsInitialized: boolean = false;
	@Watch('selectedQueue') async loadQueueJobs(): Promise<void>{
		this.jobsLoading = true;
		this.jobs = await backendJobApi.getJobs(this.selectedQueue.name).catch(() => []);
		this.jobsLoading = false;
		this.jobsInitialized = true;
		this.persistField('selectedQueue');
	}
	jobs: BackendJob[] = [];
	get Jobs(): BackendJob[]{
		return this.jobs.map(j => ({
			...j,
			finishedOn: j.finishedOn > 0 ? new Date(j.finishedOn) : 0,
			processedOn: j.processedOn > 0 ? new Date(j.processedOn) : 0,
			timestamp: j.timestamp > 0 ? new Date(j.timestamp) : 0,
		} as any));
	}

	queues: BackendQueue[] = [];
	get Queues(): BackendQueue[] {
		return this.queues;
	}
	queuesLoading: boolean = false;
	queuesInitialized: boolean = false;
	private async loadQueues(): Promise<void>{
		this.queuesLoading = true;
		this.queues = await backendJobApi.getAllQueues().catch(() => []);
		this.queuesLoading = false;
		this.queuesInitialized = true;
	}

	async mounted(): Promise<void>{
		this.loadQueues();
	}

	confirmRemoveCompletedJobs: boolean = false;
	async removeCompletedJobs(): Promise<void>{
		await backendJobApi.removeCompletedJobs(this.selectedQueue.name);
		this.confirmRemoveCompletedJobs = false;
		await this.loadQueueJobs();
	}
	confirmRemoveFailedJobs: boolean = false;
	async removeFailedJobs(): Promise<void>{
		await backendJobApi.removeFailedJobs(this.selectedQueue.name);
		this.confirmRemoveFailedJobs = false;
		await this.loadQueueJobs();
	}
}
