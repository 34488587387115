
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { BAIconsMixin, FormRulesMixin, StatusMixin, VuetifyMixin, StringsMixin, PaginatedTableMixin, SportsSelectorMixin } from '@/mixins';
import { BASchoolModel } from "@/models/baSchool";
import { baSchoolStore, notificationStore } from '@/store'
import { DataTableHeader } from 'vuetify';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import BASchoolsImport from '@/components/admin/BASchoolsImport.vue';
import BASchoolsLogoImport from '@/components/admin/BASchoolsLogoImport.vue';
import SchoolCoachesImport from '@/components/admin/schoolSearch/SchoolCoachesImport.vue'
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import { SportName } from '@/../types/enums';
import { SportGender } from '@/../types/enums'

@Component({
	components: {
		Page,
        FileUploadArea,
        BASchoolsImport,
        BASchoolsLogoImport,
        SchoolCoachesImport,
        RecruitingProfileInfo
	}
})
export default class BASchoolAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, SportsSelectorMixin, FormRulesMixin, StatusMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');

    search: string = '';
    sportsOnly: boolean = true;
    sport: SportName;
    gender: SportGender;
    showImportBASchoolsDlg: boolean = false;
    viewDetails: boolean = false;

    get GenderValues(): SportGender[] {
        return [
            SportGender.MEN,
            SportGender.WOMEN
        ]
    }
    
	mounted() {
		this.tableOptions.sortBy = ['name'];
	}

    async loadTable() {
		this.pageState = new PageState('Loading');
        try {
            var query = this.TableQuery<BASchoolModel>(this.search, ['name', 'location.city', 'location.state', 'ipedsId', 'cd_schoolID']);
            if( this.sportsOnly ) { query.$match = {...query.$match, ...{ hasSports: true } } };
            if( this.IsNotEmpty(this.gender) ) { query.$match = {...query.$match, ...{ 'sports.gender': this.gender } } };
            if( this.IsNotEmpty(this.sport) ) { query.$match = {...query.$match, ...{ 'sports.name': this.sport } } };
            await baSchoolStore.loadBASchools({query, options: this.TableQueryOptions });
        } catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading schools: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
    }

    get PageLoading(): boolean {
        return this.tableUpdatePending || baSchoolStore.loadingBASchools;
    }
    get TableLoading(): boolean {
        return this.tableUpdatePending;
    }
    get TotalItems(): number {
        return baSchoolStore.numBASchools;
    }
    get BASchools(): BASchoolModel[] {
        return baSchoolStore.baSchools
    }

    get TableHeaders(): DataTableHeader<any>[] {
        return [
            {text: 'Name', value: 'name', sortable: false},
            {text: 'IPED', value: 'ipedsId', sortable: false},
            {text: 'Collegiate Directories', value: 'cd_schoolID', sortable: false},
            {text: 'Sports', value: 'NumSports', sortable: false},
            {text: 'Division', value: 'Division', sortable: false},
            {text: '', width:"50", value: 'actions', sortable: false},
            {text: '', width:"25", value: 'data-table-expand'}
        ]
    }

    // Edit School dialogue
    editTitle: string = "Edit Best Athletes School";
    backupBASchool: BASchoolModel;
    editBASchool: BASchoolModel = new BASchoolModel;
    validBASchool: boolean;
    editingBASchool: boolean = false;
    onAddBASchool() {
        this.editTitle = "Add BASchool";
        var newBASchool = new BASchoolModel();
        this.StartBASchoolEdit(newBASchool);
    }
    onEditBASchool(baSchool: BASchoolModel) {
        this.editTitle = `Edit \'${baSchool.name}\'`;
        this.StartBASchoolEdit(baSchool);
    }
    StartBASchoolEdit(baSchool: BASchoolModel) {
        this.backupBASchool = new BASchoolModel;
        Object.assign(this.backupBASchool, baSchool);
        this.editBASchool = baSchool;
        this.editingBASchool = true;
    }
    async onDeleteBASchool(baSchool: BASchoolModel) {
        if( !confirm(`Delete ${baSchool.name}? This action cannot be undone`) ) return;

        await baSchoolStore.deleteBASchool(baSchool);
        this.loadTable();
    }
    onCancelEditDlg() {
        Object.assign(this.editBASchool, this.backupBASchool);
        this.editingBASchool = false;
    }
    async onSaveEditDlg() {
        if( !(this.$refs.form as HTMLFormElement).validate() ) {
            this.setError( "Please complete all of the required fields" );
            return;
        }

        try {
            await baSchoolStore.saveBASchool(this.editBASchool);
            this.editingBASchool = false;
            this.loadTable();
        }
        catch(e) {
            console.log(e)
        }
    }

    async onCleanupDatabase() {
        await baSchoolStore.cleanupDatabase();
        this.loadTable();
    }
}
