
import { Component, Mixins } from 'vue-property-decorator';
import Page from '@/views/Page.vue';
import { PageState } from '@/models/PageState';
import { VuetifyMixin, PaginatedTableMixin, StringsMixin, BAIconsMixin } from '@/mixins';
import { ScoutingReportModel } from '../../models/scoutingReport/ScoutingReportModel';
import { adminStore, notificationStore } from '@/store';
import { DataTableHeader } from 'vuetify';
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import AdminOrganizationAutocomplete from '@/components/admin/AdminOrganizationAutocomplete.vue';
import { AthleteProfileModel } from '@/models';

import { formatDatePretty } from '@/helpers';
import RecruitingProfileInfo from '../../components/profile/recruiting/RecruitingProfileInfo.vue';

class ScoutingReportTableData {
	id: string;
	name: string;
	athleteId: string | null;
	team: string;
	score: number;
	date: Date;
	scoutingReport: ScoutingReportModel;
}

@Component({
	components: {
		Page,
		AdminOrganizationAutocomplete,
		AdminAthleteAutocomplete,
		RecruitingProfileInfo
	}
})
export default class ScoutingReportsAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';

	mounted(): void{
		this.tableOptions.sortBy = ['date'];
		this.tableOptions.sortDesc = [true];
	}

	async loadTable(): Promise<void> {
		try {
			var query = this.TableQuery<ScoutingReportModel>(this.search, ['athleteId', 'playerDetails.lastName', 'playerDetails.firstName', 'scoutingReport.gameDetails.teamName']);
			await adminStore.loadScoutingReports({ query, options: this.TableQueryOptions });
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error loading mindset: ${e}`});
		} finally {
			this.pageState = new PageState('Ready');
		}
	}

	get PageLoading(): boolean {
		return adminStore.scoutingReportsLoading || !adminStore.scoutingReportsInitialized;
	}
    get TotalItems(): number {
        return adminStore.scoutingReportsCount;
    }
	get MindsetReports(): ScoutingReportModel[] {
		return adminStore.scoutingReports;
	}

	get TableHeaders(): DataTableHeader<any>[] {
		return [
			{ text: 'Date', value: 'date', sortable: true },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Player', value: 'name', sortable: false },
			{ text: 'Team', value: 'team', sortable: false },
			{ text: 'Performance', value: 'score', sortable: false },
			{ text: 'AthleteId', value: 'athleteId', sortable: false },
			{ text: '', value: 'actions', sortable: false },
			{ text: '', width:"25", value: 'data-table-expand' }
		]
	}

	get ScoutingReports(): ScoutingReportTableData[] {
		if( !adminStore.scoutingReportsInitialized) return [];

		return adminStore.scoutingReports.map(scoutingReport => { 
			return {
				id: scoutingReport.id,
				name: scoutingReport.PlayerName,
				athleteId: scoutingReport.athleteId,
				team: scoutingReport.gameDetails.teamName,
				score: scoutingReport.evaluationMatrix.performanceScore,
				date: scoutingReport.gameDetails.evaluationDate,
				scoutingReport: scoutingReport
			}
		});
	}
	get ScoutingReportsCount(): number {
		return adminStore.scoutingReportsCount;
	}

	editingReport: boolean = false;
	editReport: ScoutingReportModel | null = null;
	editLinkAthlete: AthleteProfileModel | null = null;
	onLinkScoutingReport(report: ScoutingReportModel): void {
		this.editReport = report;
		this.editingReport = true;
	}
	onUpdateEditAthlete(athlete: AthleteProfileModel): void {
		this.editLinkAthlete = athlete?? null;
	}
	onCancelEdit(): void {
		this.editReport = null;
		this.editingReport = false;
	}
	onRemoveLink(): void {
		try {
			this.editReport.athleteId = null;
			this.editReport.lastModified = new Date();
			this.updateScoutingReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	onConfirmEdit(): void {
		if( !this.editLinkAthlete ) return;

		try {
			this.editReport.athleteId = this.editLinkAthlete.id;
			this.editReport.lastModified = new Date();
			this.editReport.playerDetails.firstName = this.editLinkAthlete.firstName;
			this.editReport.playerDetails.lastName = this.editLinkAthlete.lastName;
			if( this.editLinkAthlete.birthDate ) {
				this.editReport.playerDetails.dateOfBirth = this.editLinkAthlete.birthDate;
				this.editReport.playerDetails.yearOfBirth = this.editLinkAthlete.birthDate.getFullYear();
			}
			this.updateScoutingReport(this.editReport);
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}
	async updateScoutingReport(scoutingReport: ScoutingReportModel): Promise<ScoutingReportModel> {
		try {
			const updatedReport = await adminStore.patchScoutingReport(scoutingReport);
			if( this.editReport.athleteId ) {
				notificationStore.pushSnackbarSuccess({message: "Report " + scoutingReport.id + " linked to " + this.editReport.PlayerName})
			} else {
				notificationStore.pushSnackbarWarning({message: "Report " + scoutingReport.id + " UNLINKED FROM " + this.editReport.PlayerName})
			}
			this.editReport = null;
			this.editingReport = false;
			this.editLinkAthlete = null;
			this.updateSearch();
			return updatedReport;
		} catch(e) {
			notificationStore.pushSnackbarError(e);
		}
	}

	onAthleteClick(scoutingReport: ScoutingReportTableData) {
		// TODO: Admin Athlete view
	}
	onViewScoutingReport(reportId: string): void {
		// not implemented - requires an ADMIN APP scouting review form or routing to coach app
	}

}
