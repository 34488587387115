
import { Component, Prop, Mixins } from 'vue-property-decorator';


import FormAddArea from '@/components/forms/FormAddArea.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import { SocialMediaInput } from "@/components/forms/SocialMediaInput.vue";

import { FormRulesMixin, CountriesSelectorMixin, AvailableCurrencies, AppHostnameMixin, BAIconsMixin } from '@/mixins';
import { SchoolModel } from '@/models/school/SchoolModel';

import { Conferences } from "@/../types/constants/conferences";

@Component({
	components: { FormAddArea, FileUploadArea, SocialMediaInput },
})
export class SchoolCreateLocationInfo extends Mixins(AvailableCurrencies, CountriesSelectorMixin, FormRulesMixin, AppHostnameMixin, BAIconsMixin){

	showConfirmDelete = false;
	
	Conferences = Conferences;

	@Prop({ default: true }) valid: boolean;
	inputValid(): void{
		this.$emit('update:valid', this.valid);
	}

	@Prop({ required: true }) value: SchoolModel;
	@Prop({ required: true }) locationIndex: number;

	input(): void{
		this.$emit('input', this.value);
	}

	deleteLocation(): void{
		this.value.locations.splice(this.locationIndex, 1);
		this.input();
	}

	get Hide() : boolean {
		return !this.value.hide;
	}
	set Hide(v : boolean) {
		this.value.hide = !v;
	}

	changeIds: boolean = false;
	get UnlockIds(): boolean{
		return this.changeIds;
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.valid === false) return;
		this.$emit('submit', this.value);
	}
}
export default SchoolCreateLocationInfo;
