
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { backendJobApi } from '@/api/BackendJobApi';
import { VuetifyMixin, BAIconsMixin } from '@/mixins';
import { BackendJob } from '@/../types';
import { DataTableHeader } from 'vuetify';

@Component({
	components: { },
})
export default class VerifiedAssessmentImportStatus extends Mixins(VuetifyMixin, BAIconsMixin) {
  @Prop({ type: Boolean, default: false }) loading: boolean;
  @Prop({default: []}) jobIds?: number[];
  @Prop() totalItems: number;
  @Prop() value: number;

  jobs: BackendJob[] = [];
  interval: number = null;
  errorDialogVisible: boolean = false;

  headers: Array<DataTableHeader<any>> = [
    {
      text: 'Last Name',
      value: 'data.payload.data.contact.lastName',
      sortable: false,
    },
    {
      text: 'Job ID',
      value: 'id',
      sortable: false,
    },
    {
      text: 'Failed Reason',
      value: 'failedReason',
      sortable: false,
    },
    {
      text: 'Trace',
      value: 'stacktrace',
      sortable: false,
    },
  ];

  get Jobs(): BackendJob[] {
    return this.jobs;
  }

  get JobIds(): number[] {
    return this.jobIds;
  }

  get CompletedJobs(): BackendJob[] {
    return this.jobs.filter(job => job.finishedOn > 0  && (!job.stacktrace || job.stacktrace.length === 0));
  }

  get PendingJobs(): BackendJob[] {
    return this.jobs.filter(job => job.finishedOn === null);
  }

  get FailedJobs(): BackendJob[] {
    return this.jobs.filter(job => job.finishedOn > 0 && (job.stacktrace && job.stacktrace.length > 0));
  }

  showErrors() {
    // show dialog with failed jobs once we determine how an error state is handled
    this.errorDialogVisible = true;
  }

  uploadStatus: number = -1;
	@Watch('value')
	resetForm(uploadStatus) {
    this.uploadStatus = uploadStatus;
  }

  @Watch('jobIds') async jobIdsChanged() {
    // if this component has been passed an array of jobIds
    if (this.JobIds.length > 0) {
      // get the jobs from the queue and update state
      await this.getJobsInQueue();

      // if there are pending jobs then refetch jobs
      // status every 5s
      if (this.PendingJobs.length > 0) {
        this.interval = setInterval(function () {
          this.getJobsInQueue();
        }.bind(this), 5000);
      }
    }

    // if we have a interval running and there are no
    // more pending jobs then clear the interval
    if (this.interval && this.PendingJobs.length === 0) {
      clearInterval(this.interval);
    }
  }

  @Watch('PendingJobs') async pendingJobsChanged() {
    // if our pending jobs array has changed then check
    // the interval and clear if all jobs have been completed
    if (this.interval && this.PendingJobs.length === 0) {
      clearInterval(this.interval);
      this.$emit('complete');
    }
    if (this.PendingJobs.length > 0) {
      this.$emit('completedJob');
    }
  }

  async getJobsInQueue(): Promise<void> {
    const jobs = (await backendJobApi.getJobs(
      'AthleteAssessmentData:EventQueue',
      { params: {
        start: 0,
        end: this.jobIds.length * 2,
      }}
    ).catch(() => []));

    // since the api request will pull more jobs than we
    // need we need to filter out the ones we don't need
    this.jobs = jobs.filter(job => this.JobIds.includes(Number(job.id)));
  }

  beforeDestroy(): void {
    clearInterval(this.interval);
  }
}
