
import { Component, Mixins, Prop } from "vue-property-decorator";
import { VuetifyMixin, BAIconsMixin } from "@/mixins";
import { SocialMediaField } from '@/../types/interfaces';
import { SocialMedia, SocialMediaKeys } from '@/../types/enums';

type Item<T> = {
	text: string;
	value: T;
};

@Component
export class SocialMediaInput extends Mixins(VuetifyMixin, BAIconsMixin) {

	@Prop() value: SocialMediaField[];

	get Value(): SocialMediaField[]{
		return this.value;
	}
	set Value(newValue: SocialMediaField[]) {
		this.$emit('input', newValue);
	}

	get platformItems(): Item<SocialMedia>[] {
		let items: Item<SocialMedia>[] = [];

		Object.keys(SocialMedia)
			.filter(x => !(parseInt(x) >= 0))
			.forEach(media_key => {
				items.push({
					text: media_key as SocialMediaKeys,
					value: SocialMedia[media_key]
				});
			});
		return items;
	}
}
export default SocialMediaInput;
