import { render, staticRenderFns } from "./VerifiedAssessmentImportV4.vue?vue&type=template&id=50b8425c&scoped=true&"
import script from "./VerifiedAssessmentImportV4.vue?vue&type=script&lang=ts&"
export * from "./VerifiedAssessmentImportV4.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50b8425c",
  null
  
)

export default component.exports