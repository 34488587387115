
import { Component, Mixins } from 'vue-property-decorator';
import { CrudApi } from '../../api/CrudApi';
import Page from '../Page.vue';
import { RESOURCES } from '@/../types/constants/resources';
import { athleteApi } from '../../api/AthleteApi';
import { notificationStore } from '../../store/index';
import { BAIconsMixin } from '@/mixins';
import { orgAdminStore } from '@/store';

@Component({
	components:{
		Page,
	}
})
export default class SchemaUpgrade extends Mixins(BAIconsMixin){
	version: string = ""
	selectedResource: string = ""
	RESOURCES: string[] = RESOURCES;

	get SchemaUpgradeReady(): boolean{
		return !!this.selectedResource && !!this.version;
	}
	get SchemaUpgradeResponse(): string{
		return this.schemaUpgradeResponse;
	}
	get UpgradeURL(): string{
		return `${this.selectedResource}/schema-upgrade/${this.version}`;
	}
	schemaUpgradeResponse: string = undefined;

	loading: boolean = false;
	async submit(): Promise<void>{
		this.schemaUpgradeResponse = undefined;
		this.loading = true;
		try{
			const res = await CrudApi.Api(c => c.post(this.UpgradeURL));
			this.schemaUpgradeResponse = JSON.stringify(res, null, 2);
		}catch(e){
			console.error(e);
		}finally{
			this.loading = false;
		}
	}

	async setVisibilityToAll() {
		try {
			const numAthletes = await athleteApi.setVisibilityToAll()
			notificationStore.pushSnackbarSuccess({message: `Visibility updated for ${numAthletes} athletes`});
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error Setting Visibility: ${e}`});
		}
	}

	async onReIndex(orgId: string): Promise<void> {
		await orgAdminStore.createLeaderboardIndex(orgId);
		notificationStore.pushSnackbarSuccess({message: 'Organization Players queued to sync in the background'});
	}
}
