
import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import { VuetifyMixin } from "@/mixins/VuetifyMixin";
import { FormRulesMixin, RyzerMixin, BAIconsMixin } from '@/mixins';
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import { AthleteProfileModel } from '@/models';


@Component({
	components: { 
		AdminAthleteAutocomplete,
		RecruitingProfileInfo
	 }
})
export default class MindsetAddReportDialog extends Mixins(FormRulesMixin, VuetifyMixin, RyzerMixin, BAIconsMixin) {
	@Prop({ default: false, type: Boolean }) show: boolean;

	input(show: boolean): void{ this.$emit('update:show', show); }

	$refs:{
		form: HTMLFormElement
	}

	@Watch('show')	
	resetForm(newValue: boolean): void {
		// Reset validation state when showing the form
		if(newValue && this.$refs.form){
			this.$refs.form.reset();
		}
	}

	defaultForm = (): Record<string, any> => ({
		athlete: null,
		archetype: null,
		overall: null,
		mentalToughness: null,
		composure: null,
		confidence: null,
		drive: null,
		grit: null,
		mentalExecution: null,
		adaptability: null,
		decisiveness: null,
		focusSpeed: null,
		coachability: null,
		attentionToDetail: null,
		conventionality: null,
		growthMindset: null,
		informationProcessing: null,
	});
	mindsetForm = this.defaultForm();
	formValid: boolean = false;
	
	onAthleteSelected( athlete: AthleteProfileModel ){
		this.mindsetForm.athlete = athlete?? null;
	}
	get IsAthleteSelected(): boolean {
		return !!this.mindsetForm.athlete;
	}

	cancel(): void {
		this.input(false)
	}
	
	submitForm(): void {
		this.$emit("submit", this.mindsetForm);
		this.input(false);
	}
}
