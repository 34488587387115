import { UserAccountModel } from '@/models/user/UserAccountModel';
import { CrudApi } from './CrudApi';
import { PaginatedResponse } from '@/../types/interfaces';
import { isEmpty, isEmptyArray } from '@/pipes';

class UserAccountApi extends CrudApi<UserAccountModel>{
	constructor() {
		super('user', (obj) => new UserAccountModel().load(obj));
	}

	syncUsers(): Promise<{ count: number }>{
		return CrudApi.Api(a => a.post(`${this.resource}/sync-all`));
	}

	async syncUserById(userId: string): Promise<void>{
		await CrudApi.Api(a => a.post(`${this.resource}/sync/${userId}`));
	}

	async findDuplicates(search: string): Promise<Array<UserAccountModel>> {
		try{
			const response: Array<UserAccountModel> = await CrudApi.Api(a => a.post(`${this.resource}/find-duplicates/${search}`));
			if( isEmptyArray(response) ) return [];
			return response.map(u => { return new UserAccountModel().load(u) });
		} catch(e) {
			return [];
		}
	}
	async findUserDuplicates(authId: string): Promise<Array<UserAccountModel>> {
		try{
			const response: PaginatedResponse<UserAccountModel> = await CrudApi.Api(a => a.post(`${this.resource}/user-duplicates/${authId}`));
			if( isEmpty(response) ) return [];
			return response.docs;
		} catch(e) {
			return [];
		}
	}
	async mergeAccounts(userIds: Array<string>): Promise<void> {
		await CrudApi.Api(a => a.post(`${this.resource}/merge/${userIds.join('&')}`));
	}
	async mergeSkipAccounts(userIds: Array<string>): Promise<void> {
		await CrudApi.Api(a => a.post(`${this.resource}/merge-skip/${userIds.join('&')}`));
	}
}
export const userAccountApi = new UserAccountApi();