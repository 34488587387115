
import { Component, Mixins } from 'vue-property-decorator';
import { PageState } from '@/models/PageState';
import { DebounceMixin, LocalForageMixin, VuetifyMixin, BAIconsMixin } from '@/mixins';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import Dialog from '@/components/ui/Dialog.vue';
import { TeamGenderValues } from '@/../types/enums';
import { CoachInfo } from '@/../types/interfaces/CoachInfo';
import { CSVUploadMixin } from '@/mixins/CSVUploadMixin';
import { schoolApi } from '@/api/SchoolApi';
import { baSchoolApi } from '@/api/BASchoolApi';


@Component({
	components: {
		CSVUploadDialog,
		Dialog,
		ExcelDataTable,
	}
})
export default class SchoolCoachesImport extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin, CSVUploadMixin, BAIconsMixin) {
	pageState = new PageState("Ready");

	notifyCoaches = false;

	sampleCoaches: CoachInfo[] = [
		{
			SchoolID: "301",
			IPED: "196413",
 			PersonID: "289640",
			NameFirst: "Ian",
			NameLast: "McIntyre",
			NameFull: "Ian McIntyre",
			title: "Head Men's Soccer Coach",
			SchoolName: "Syracuse University",
			SchoolNameAlpha: "SYRACUSE UNIVERSITY",
			Address1: "1301 E. Colvin St",
			Address2: "Manley Field House",
			City: "Syracuse",
			State: "NY",
			Zip: "13244-5020",
			Phone: "315-443-3025",
			Fax: "315-443-3405",
			email: "ismcinty@syr.edu",
			DivCode: "1",
			Division: "NCAA 1",
			sport: "Soccer",
			Gender: "M",
			IsHeadCoach: "True",
			Rank: "1",
			Conference: "ACC",
		},
	];
	
	parseSchoolCoaches = (csvString: string): CoachInfo[] => {
		const rowJson = this.csv2json(csvString);
		const coaches = rowJson.map(row => {
			const coach: CoachInfo = {
				SchoolID: row.SchoolID.replace(/^0+/, ''),
				IPED: row.IPED,
				PersonID: row.PersonID,
				NameFirst: row.NameFirst,
				NameLast: row.NameLast,
				NameFull: row.NameFull,
				title: row.title,
				SchoolName: row.SchoolName,
				SchoolNameAlpha: row.SchoolNameAlpha,
				Address1: row.Address1,
				Address2: row.Address2,
				City: row.City,
				State: row.State,
				Zip: row.Zip,
				Phone: row.Phone,
				Fax: row.Fax,
				email: row.email,
				DivCode: row.DivCode,
				Division: row.Division,
				sport: row.sport,
				Gender: row.Gender,
				IsHeadCoach: row.IsHeadCoach,
				Rank: row.Rank,
				Conference: row.Conference,
			}
			return coach;
		});
		return coaches;
	};

	showUploadCoachesDlg = false;
	showUploadPreview = false;

	unconfirmedCoaches: Array<CoachInfo> = [];
	get CoachesRowPreview(): Array<any>{
		return this.unconfirmedCoaches;
	}

	onSchoolCoachesLoaded(data: Array<CoachInfo>): void {
		this.unconfirmedCoaches = data;
		this.showUploadPreview = true
	}
	startOver(): void{
		this.importingCoaches = false;
		this.showUploadPreview = false;
		this.unconfirmedCoaches = [];
	}

	importingCoaches: boolean = false;
	get isImporting(): boolean {
		return this.importingCoaches;
	}
	coachesUpdated: number = 0;
	coachUpdateError: string;
	async confirmSchoolCoaches() {
		this.importingCoaches = true;

		this.$emit('submit', {
			coaches: this.unconfirmedCoaches,
			notify: this.notifyCoaches,
		});

		this.showUploadPreview = false;

		// TODO: Add update notifications
		await schoolApi.updateAllCoaches(this.unconfirmedCoaches);
		await baSchoolApi.updateAllCoaches(this.unconfirmedCoaches)
		this.$emit('updated')
		this.startOver();
	}
	abort: boolean = false;
	onAbortUpload() {
		this.abort = true;	
	}
	get CoachesCount(): number{
		return this.unconfirmedCoaches.length;
	}
	get EmailCount(): number{
		return this.unconfirmedCoaches.filter(a => !!a.email).length;
	}
}
