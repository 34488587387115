
import Page from '../Page.vue';
import { PageState } from '@/models/PageState';

import { notificationStore } from '../../store/index';

import { adminStore } from '@/store';
import { mindsetReportApi } from '@/api/MindsetReportApi'
import { athleteApi } from '@/api/AthleteApi';

import { Component, Mixins } from 'vue-property-decorator';
import ConfirmationDialog from "@/components/ui/ConfirmationDialog.vue";
import MindsetAddReportDialog from "./MindsetAddReportDialog.vue";
import MindsetImportDialog from "./MindsetImportDialog.vue";
import { DataTableHeader } from 'vuetify';
import { MindsetReportModel, AthleteProfileModel } from '@/models';
import { VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin } from '@/mixins';
import AdminAthleteAutocomplete from '@/components/admin/AdminAthleteAutocomplete.vue';
import RecruitingProfileInfo from "@/components/profile/recruiting/RecruitingProfileInfo.vue";
import { coachApi } from '@/api/CoachApi';

class MindsetReportImport extends MindsetReportModel {
	hasUser: boolean = false;
	isDuplicate: boolean = false;

	StatusIcon(hover: boolean): string {
		if( !this.hasUser ) return 'mdi-account-off';
		if( this.isDuplicate ) return 'mdi-alert';

		return 'mdi-clipboard-text-play-outline';				
	}

	StatusColor(hover: boolean = true): string {
		if( !this.hasUser ) return 'error';
		if( this.isDuplicate ) return 'error';

		return hover? 'primary' : 'grey';
	}

	get Status(): string {
		if( !this.hasUser ) return 'Athlete account not found. Report will not be uploaded';
		if( this.isDuplicate ) return 'Duplicate report will not be uploaded';

		return `New mindset report for ${this.FullName}`;
	}
}

@Component({
	components:{
		Page,
		ConfirmationDialog,
		MindsetAddReportDialog,
		MindsetImportDialog,
		AdminAthleteAutocomplete,
		RecruitingProfileInfo
	}
})
export default class MindsetReportsAdminDashboard extends Mixins(VuetifyMixin, StringsMixin, PaginatedTableMixin, BAIconsMixin){
	pageState: PageState = new PageState('Initial');
	search: string = '';
	showAddReportDialog: boolean = false;
	showImportDialog: boolean = false;

	mounted(): void{
		this.tableOptions.sortBy = ['date'];
		this.tableOptions.sortDesc = [true];
	}
	async loadTable(): Promise<void>{
		this.pageState = new PageState('Loading');
		try{
			var query = this.TableQuery<MindsetReportModel>(this.search, ['athleteId', 'email', 'firstName', 'lastName']);
			await adminStore.loadMindsetReports({ query, options: this.TableQueryOptions });
		}catch(e){
			notificationStore.pushSnackbarError({message: `Error loading mindset: ${e}`});
		}finally{
			this.pageState = new PageState('Ready');
		}
	}

	get PageLoading(): boolean {
		return adminStore.mindsetReportsLoading;
	}
    get TotalItems(): number {
        return adminStore.mindsetReportsCount;
    }
	get MindsetReports(): MindsetReportModel[] {
		return adminStore.mindsetReports;
	}

	get TableHeaders(): DataTableHeader<any>[] {
		return [
			{ text: 'Date', value: 'date', sortable: false },
			{ text: 'ID', value: 'id', sortable: false },
			{ text: 'Athlete', value: 'lastName', sortable: false },
			{ text: 'Email', value: 'email', sortable: false },
			{ text: 'MT', value: 'mentalToughness' },
			{ text: 'ME', value: 'mentalExecution' },
			{ text: 'C', value: 'coachability' },
			{ text: 'Athlete Id', value: 'athleteId', sortable: false },
			{ text: '', value: 'delete', sortable: false },
			{ text: '', value: 'data-table-expand', sortable: false }
		];
	}

	searchNew: string = ""
	async addMindsetReportFromForm(form: Record<string, any>): Promise<void>{
		const mindsetReport = new MindsetReportModel();

		mindsetReport.archetype = form.archetype;
		mindsetReport.athleteId = form.athlete.id;
		mindsetReport.firstName = form.athlete.firstName;
		mindsetReport.lastName = form.athlete.lastName;
		mindsetReport.email = form.athlete.email;
		mindsetReport.dateOfBirth = form.athlete.dateOfBirth;
		mindsetReport.position = form.athlete.primaryPosition;
		mindsetReport.school = form.athlete.highschoolName;
		mindsetReport.mentalToughness = form.mentalToughness;
		mindsetReport.composure = form.composure;
		mindsetReport.confidence = form.confidence;
		mindsetReport.drive = form.drive;
		mindsetReport.grit = form.grit;
		mindsetReport.mentalExecution = form.mentalExecution;
		mindsetReport.adaptability = form.adaptability;
		mindsetReport.decisiveness = form.decisiveness;
		mindsetReport.focusSpeed = form.focusSpeed;
		mindsetReport.coachability = form.coachability;
		mindsetReport.attentionToDetail = form.attentionToDetail;
		mindsetReport.conventionality = form.conventionality;
		mindsetReport.growthMindset = form.growthMindset;
		mindsetReport.informationProcessing = form.informationProcessing;

		// insert the mindset report into the database
		try{
			await mindsetReportApi.insert(mindsetReport);
			notificationStore.pushSnackbarSuccess({message: `Added mindset report for ${mindsetReport.FullName}`});
			this.updateSearch();
		} catch(e) {
			notificationStore.pushSnackbarError({message: `Error adding report for ${mindsetReport.athleteId}: ${e}`})
		}
	}

	onAthleteClick(report: MindsetReportModel) {
		// TODO: Admin Athlete view
	}

	async onDeleteReport(report: MindsetReportModel) {
		if( confirm(`Are you sure you want to delete this mindset report for ${report.FullName}?`) ) {
			try{
				await mindsetReportApi.delete(report);
				notificationStore.pushSnackbarWarning({message: `Deleted mindset report for ${report.FullName}`});
				this.updateSearch();
			} catch(e) {
				notificationStore.pushSnackbarError({message: `Error deleting report ${report.id}: ${e}`})
			}
		}
	}

	onCancelUpload() {
		this.newMindsetReports = null;
	}
	async onAcceptUpload(): Promise<void> {
		var newReportCount = 0;
		for( const report of this.newMindsetReports ){
			if( report.hasUser && !report.isDuplicate ) {
				try{
					const uploadReport: MindsetReportModel = report;
					await mindsetReportApi.insert(uploadReport);
					newReportCount += 1;
				} catch(e) {
					notificationStore.pushSnackbarError({message: `Error adding report for ${report.athleteId} - ${report.FullName}: ${e}`})
				}
			}
		}

		if( newReportCount > 0 ) {
			notificationStore.pushSnackbarSuccess({message: `Added ${newReportCount} reports`})
			this.updateSearch();
			this.newMindsetReports = null;
		}
	}

	newMindsetReports: MindsetReportImport[] = null;
	async onMindsetReportsImport(reports: MindsetReportModel[]): Promise<void>{
		this.newMindsetReports = [];
		for(const report of reports) {
			var athlete = undefined;
			var coach = undefined;
			var userId: string = undefined;
			athlete = await athleteApi.findByContact({
					email: report.email,
					firstName: report.firstName,
					lastName: report.lastName
				}
			);
			if( athlete ) {
				userId = athlete.id;
			} else {
				coach = await coachApi.findByContact({
					email: report.email,
					firstName: report.firstName,
					lastName: report.lastName
				})
				userId = coach?.id;
			}

			// check for duplicates
			var importReport: MindsetReportImport = new MindsetReportImport;
			Object.assign(importReport, {
				...report, 
				hasUser: !!athlete || !!coach,
				isDuplicate: false,
			})
			if( importReport.hasUser ) {
				const previousReports = await mindsetReportApi.findAllByAthleteId(userId);
				if( previousReports ) {
					for( const previous of previousReports) {
						if( previous.mentalToughness == report.mentalToughness &&
							previous.mentalExecution == report.mentalExecution &&
							previous.coachability == report.coachability &&
							previous.overall == report.overall ) {
							importReport.isDuplicate = true;
						}
					}
				}
			}

			importReport.athleteId = userId;

			// clean up data
			importReport.mindsetFit = Number(importReport.mindsetFit);		// mindset fit is sometimes N/A in the spreadsheet. convert to a number value

			this.newMindsetReports.push(importReport);
		}
	}

	onRemoveNewReport(report: MindsetReportImport): void {

	}
}
