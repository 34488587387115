
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { FormRulesMixin, BAIconsMixin } from '@/mixins';
import AdminAutoCompleteInput from '@/components/forms/AdminAutoCompleteInput.vue';
import { TeamModel, PlayerOnTeam } from '@/models/team';
import TeamDetailsCard from '@/components/teams/TeamDetailsCard.vue';
import TeamMiniCard from '@/components/ui/TeamMiniCard.vue';
import FileUploadArea from '@/components/forms/FileUploadArea.vue';
import CSVUploadDialog from "@/components/teams/CSVUploadDialog.vue";
import CSVUploadPreview from "@/components/teams/CSVUploadPreview.vue";
import { Gender, StaffRoleValues, StaffRole, SportName } from '@/../types/enums';
import { OrganizationMemberImportData } from '@/../types/interfaces/OrganizationMemberImportData';
import { StaffMember } from '@/models/team/StaffMember';
import { stringToAlphaLower } from '@/pipes/stringToAlpha.pipe';
import { OrganizationModel } from '../../models/organization/OrganizationModel';

type TreeViewItem = {
	name: string;
	children?: TreeViewItem[];
};

@Component({
	components: {
		AdminAutoCompleteInput,
		TeamDetailsCard,
		TeamMiniCard,
		FileUploadArea,
		CSVUploadDialog,
		CSVUploadPreview,
	},
})
export default class OrganizationCreateLinkTeams extends Mixins(FormRulesMixin, BAIconsMixin){
	get TeamGenderValues(): string[]{
		return [Gender.Male, Gender.Female];
	}
	get RoleValues(): string[]{
		return [...StaffRoleValues];
	}
	trimEnumValue(val: string): string{
		return stringToAlphaLower(val);
	}
	getGender(gender: string): Gender | undefined{
		if(this.trimEnumValue(gender) === this.trimEnumValue(Gender.Male)){
			return Gender.Male;
		}
		if(this.trimEnumValue(gender) === this.trimEnumValue(Gender.Female)){
			return Gender.Female;
		}
		return undefined;
	}
	getRole(role: string): StaffRole{
		if(this.trimEnumValue(role) === this.trimEnumValue(StaffRole.Admin)){
			return StaffRole.Admin;
		}
		if(this.trimEnumValue(role) === this.trimEnumValue(StaffRole.HeadCoach)){
			return StaffRole.HeadCoach;
		}
		return StaffRole.AssistantCoach;
	}

	get OrgName(): string {
		return this.baseOrg? this.baseOrg.name : "Organization";
	}

	headers = [
		{ text: 'Team', value: 'name' },
		{ text: 'Gender', value: 'gender' },
		{ text: 'City', value: 'city' },
		{ text: 'Country', value: 'country' },
		{ text: 'Contact', value: 'contact' }
	];

	sampleCSVData: OrganizationMemberImportData[] = [
		{
			team: "Athletic",
			gender: Gender.Male,
			seasonFormat: "year",
			firstName: "Zephyr",
			lastName: "Jefferson",
			role: "Coach",
			email: "zeph101@ba.co",
		},
		{
			team: "Athletic Women",
			gender: Gender.Female,
			seasonFormat: "year",
			firstName: "Abigail",
			lastName: "Armstrong",
			role: "Assistant",
			email: "abby101@ba.co",
		},
		{
			team: "Athletic Junior",
			gender: Gender.Female,
			seasonFormat: "year",
			firstName: "Brian",
			lastName: "Bonhumi",
			role: "Admin",
			email: "brian101@ba.co",
		},
	];

	// Function used to parse CSV
	csvParseFn = (csvString: string): TeamModel[] => {
		this.activeTreeItem = [];

		// parse csv into rows and columns
		const rows = csvString.split('\n').map(line => line.split(',') ).slice(1);

		// get the header keys
		const keys = Object.keys(this.sampleCSVData[0]);

		// take each row and convert json, assigning values to the keys found in the header
		// filter out any columns where information is not defined
		const rowJson: Record<string, any>[] = rows.map(row => {
			return keys.reduce((json, value, index) => {
				json[value] = row[index];
				return json;
			}, {});
		}).filter(d => d['team'] !== undefined && d['team'] != '#N/A' && d['firstName'] !== undefined && d['lastName'] !== undefined);

		// take the json format and determine unique teams
		const teams: Record<string, TeamModel> = rowJson.reduce((teamMap, row) => {
			const team = row.team;
			if(teamMap[team] === undefined){
				teamMap[team] = new TeamModel().load({...this.baseTeam.copyAsTemplate(), name: team, gender: this.getGender(row.gender), seasonFormat: row.seasonFormat});
			}
			return teamMap;
		}, {});

		// determine team staff
		const isTeam = (teamName: string) => (row: any) => row.team === teamName;
		const isStaff = (staff: any) => staff.role ? this.trimEnumValue(staff.role) !== "player" : false;
		const isPlayer = (player: any) => !isStaff(player);
		const finalTeams = Object.values(teams).map(team => {
			team.staffMembers = rowJson.filter(isTeam(team.name)).filter(isStaff).map(staff => {
				return {
					firstName: staff.firstName,
					lastName: staff.lastName,
					email: staff.email,
					role: this.getRole(staff.role),
				} as StaffMember;
			});
			team.players = rowJson.filter(isTeam(team.name)).filter(isPlayer).map(player => {
				return {
					firstName: player.firstName,
					lastName: player.lastName,
					email: player.email,
					gender: this.getGender(player.gender),
				} as PlayerOnTeam;
			});
			const hasMale = team.players.find(p => p.gender === Gender.Male) !== undefined;
			const hasFemale = team.players.find(p => p.gender === Gender.Female) !== undefined;
			if( hasMale && !hasFemale ){
				team.gender = Gender.Male
			} else if( !hasMale && hasFemale ){
				team.gender = Gender.Female
			} else if( !team.gender ) {
				team.gender = Gender.Coed
			}
			return team;
		});
		return finalTeams;
	};

	CSVUploadDialogVisible = false;
	CSVUploadPreviewModalVisible = false;

	get TeamsList(): Array<any> {
		return this.unconfirmedTeams.map(team => {return {
			name: team.name, 
			gender: team.gender,
			city: team.city,
			country: team.country,
			contact: team.HeadCoach?.lastName,
		}});
	}
	unconfirmedTeams: TeamModel[] = [];
	loadCSVData(data: TeamModel[]): void {
		this.unconfirmedTeams = data;
		this.CSVUploadPreviewModalVisible = true
	}
	startOver(): void{
		this.CSVUploadPreviewModalVisible = false;
		this.unconfirmedTeams = [];
	}
	confirmCSVData(): void {
		this.value.createTeams = this.unconfirmedTeams.slice();
		this.startOver();
	}
	get TeamCount(): number{
		return this.unconfirmedTeams.length;
	}
	get EmailCount(): number{
		return this.StaffCountWithEmails + this.PlayerCountWithEmails;
	}
	get StaffCount(): number{
		return this.unconfirmedTeams.reduce((a,b) => a + b.staffMembers.length, 0);
	}
	get StaffCountWithEmails(): number{
		return this.unconfirmedTeams.reduce((a,b) => a + b.staffMembers.filter(s => !!s.email).length, 0);
	}
	get PlayerCount(): number{
		return this.unconfirmedTeams.reduce((a,b) => a + b.players.length, 0);
	}
	get PlayerCountWithEmails(): number{
		return this.unconfirmedTeams.reduce((a,b) => a + b.players.filter(p => !!p.email).length, 0);
	}
	activeTreeItem: any[] = [];
	get ActiveStaff(): StaffMember | null{
		if(this.activeTreeItem.length === 0) return null;
		const item = this.activeTreeItem[0];
		if(!item.id) return null;
		if(item.id.indexOf('staff') > -1){
			return item.value;
		}
		return null;
	}
	get ActivePlayer(): PlayerOnTeam | null{
		if(this.activeTreeItem.length === 0) return null;
		const item = this.activeTreeItem[0];
		if(!item.id) return null;
		if(item.id.indexOf('player') > -1){
			return item.value;
		}
		return null;
	}
	get TeamTreeView(): TreeViewItem[]{
		return this.unconfirmedTeams.map((team, index) => ({
			id: index,
			name: `${team.name} (${team.staffMembers.length + team.players.length} users)`,
			value: team,
			isTeam: true,
			children:[
				{
					id: `${index}_staff`,
					name: `Staff (${team.staffMembers.length})`,
					children: team.staffMembers.map((staff, staffIndex) => ({
						id: `${index}_staff_${staffIndex}`,
						name: `${staff.firstName} ${staff.lastName} - ${staff.role} ${staff.email ? ` (${staff.email})` : ''}`,
						value: staff,
					}))
				},
				{
					id: `${index}_players`,
					name: `Players (${team.players.length})`,
					children: team.players.map((player, playerIndex) => ({
						id: `${index}_player_${playerIndex}`,
						name: `${player.firstName} ${player.lastName}${player.email ? ` (${player.email})` : ''}`,
						value: player,
					}))
				}
			]
		}))
	}

	thisYear: number = new Date().getFullYear();
	@Prop() value = {
		valid: false,
		teams: [],
		createTeams: [],
		inviteStaff: false,
		invitePlayers: false,
	};
	@Prop({ default: () => new OrganizationModel }) baseOrg: OrganizationModel;
	@Prop({ default: () => new TeamModel() }) baseTeam: TeamModel;
	input(): void{
		this.$emit('input', this.value);
	}

	filterSelected(item: TeamModel): boolean{
		return (this.value.teams.findIndex(t => t.id === item.id) === -1);
	}

	onTeamSelected(team: TeamModel): void{
		if(!team) return;
		const index = this.value.teams.findIndex(t => t.id === team.id);
		if(index > -1){
			this.value.teams.splice(index, 1, new TeamModel().load(team));
		}else{
			this.value.teams.push(new TeamModel().load(team));
		}
		this.input();
	}
	removeTeam(teamId: string): void{
		const index = this.value.teams.findIndex(t => t.id === teamId);
		if(index > -1) this.value.teams.splice(index, 1);
	}
	removeCreateTeam(teamId: string): void{
		const index = this.value.createTeams.findIndex(t => t.id === teamId);
		if(index > -1) this.value.createTeams.splice(index, 1);
	}

	$refs:{
		form: HTMLFormElement;
	}
	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
