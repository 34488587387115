
import { Component, Prop, Mixins } from 'vue-property-decorator';
import FormAddArea from '../forms/FormAddArea.vue';
import { OrganizationRoleValues } from '@/../types/enums/organization-role';
import { FormRulesMixin, BAIconsMixin } from '../../mixins';

@Component({
	components: { FormAddArea },
})
export default class OrganizationCreateStaffInfo extends Mixins(BAIconsMixin, FormRulesMixin){

	staffTypes = OrganizationRoleValues;

	addStaffMember(): void{
		this.value.staffMembers.push(this.defaultStaffMember());
	}
	removeStaffMember(index: number): void{
		this.value.staffMembers.splice(index,1);
	}
	defaultStaffMember(): Record<string, string>{
		return {
			type: "",
			firstName: "",
			lastName: "",
			email: "",
		};
	}

	@Prop({ default: () => ({
		valid: false,
		staffMembers: [],
		sendStaffInvitations: false,
	}) }) value;
	input(): void{
		this.$emit('input', this.value);
	}

	get showStaffMembers(): boolean{
		return this.value.staffMembers.length > 0;
	}

	$refs:{
		form: HTMLFormElement;
	}

	async submit($event: Event): Promise<void> {
		$event.preventDefault();
		this.$refs.form.validate();
		if(this.value.valid === false) return;
		this.$emit('submit', this.value);
	}
}
