
import { Component, Mixins } from 'vue-property-decorator';
import Page from '../Page.vue';
import { PaginatedTableMixin, StringsMixin, VuetifyMixin, BAIconsMixin } from '../../mixins';
import { RepositoryQuery } from '@/../types/interfaces';
import { UserAccountModel } from '@/models/user/UserAccountModel';
import { userAccountApi } from '@/api/UserAccountApi';
import { RoleName } from '@/../types/enums';
import { DataTableHeader } from 'vuetify';
import { TeamAthleteProgress, AthleteProgressAbbreviations } from '@/models/team'

import UsersAdminModule from '@/store/admin/UsersAdmin.store';
import { getModule } from 'vuex-module-decorators';
import { notificationStore } from '@/store';
import ProfileProvider from '@/components/hoc/ProfileProvider.vue';
import { CoachProfileModel } from '@/models/coach/CoachProfileModel';
export const usersAdminStore = getModule(UsersAdminModule);


@Component({
	components: {
		Page,
	}
})
export default class UserAdminDashboard extends Mixins(VuetifyMixin, PaginatedTableMixin, StringsMixin, BAIconsMixin){
	search: string = '';
	localForagePersistFields: Array<string | [string, any]> = [['search',''],['selectedStatuses',[]],['tableOptions.page', 1],['tableOptions.itemsPerPage', 10]];

	mounted() {
		this.tableOptions.sortBy = ['last_login'];
		this.tableOptions.sortDesc = [true];
	}

	get TableHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Last Login', value: 'last_login', sortable: false },
			{ text: 'Name', value: 'name', sortable: false },
			{ text: 'Email', value: 'email', sortable: false },
			{ text: 'UserID', value: 'user_id', sortable: false },
			{ text: 'Logins', value: 'logins_count', sortable: false },
			{ text: 'Role', value: 'roles', sortable: false },
			{ text: 'athleteId', value: 'athleteProfileId', sortable: false },
			{ text: 'childId', value: 'childProfileIds', sortable: false },
			{ text: 'coachId', value: 'coachProfileId', sortable: false },
			{ text: 'Created', value: 'created_at', sortable: false },
		];
		if( this.IsLargeScreen ) {
			headers.push({text: '', value: 'actions', sortable: false });
			headers.push({text: '', value: 'data-table-expand', sortable: false});
		}

		return headers;
	}

	usersLoading: boolean = false;
	get SearchQuery(): RepositoryQuery<UserAccountModel> {
		const query: RepositoryQuery<UserAccountModel> = this.TableQuery<UserAccountModel>(
			this.search,
			['email', 'name', 'username', 'user_metadata']
		);
		if( this.IsNotEmptyArray(this.selectedRoles) ) {
			query.$match = { roles: { $any: this.selectedRoles } }
		}

		return query;
	}
	async loadTable() {
		this.usersLoading = true;
		try{
			await usersAdminStore.loadUserList({ query: this.SearchQuery, options: this.TableQueryOptions });
		} catch(e) {
			console.error(e);
		}
		this.usersLoading = false;
	}

	get PageLoading(): boolean {
		return this.TableLoading;
	}
	get TableLoading(): boolean {
		return this.usersLoading || usersAdminStore.loading || this.syncLoading;
	}
	get TotalItems(): number {
		return usersAdminStore.totalUsers;
	}
	get Users(): Array<any> {
		return usersAdminStore.userList;
	}

	RoleName=RoleName;
	selectedRoles: RoleName[] = [];
	get UserRoles(): {text: string, value: string}[]{
		return [
			RoleName.Athlete,
			RoleName.Parent,
			RoleName.Coach,
		].map(r => ({ text: this.titleCase(r), value: r }));
	}
	RoleColor(role: RoleName): string {
		const selected = this.selectedRoles.includes(role);
		let color = `blue`;

		if( role === RoleName.Athlete ) {
			color = `purple`;
		} else if( role === RoleName.Parent ) {
			color = `teal`;
		} else if( role === RoleName.Coach ) {
			color = `green`;
		}

		const lighten = selected?  `` : ` lighten-4`;
		const textColor = selected? `` : ` ${color}--text`;
		return color + lighten + textColor;
	}
	async onToggleFilter(role: RoleName) {
		if( this.selectedRoles.includes(role) ) {
			const index = this.selectedRoles.findIndex(r => r === role);
			this.selectedRoles.splice(index, 1);
		} else {
			this.selectedRoles.push(role);
		}
		await this.updateFilter();
	}

	syncLoading: boolean = false;
	async onSyncUsers() {
		const confirm = window.confirm(`This process will take a long time. Proceed?`)
		if( confirm ) this.SyncUsersConfirm();
	}
	async SyncUsersConfirm() {
		this.syncLoading = true;

		try{
			userAccountApi.syncUsers();

			notificationStore.pushSnackbarWarning({message:`Synchronizing Users: Starting...`});
			setTimeout(() => {notificationStore.pushSnackbarWarning({message:`Synchronizing Users: In Progress...`})}, 1000);
			setTimeout(() => {notificationStore.pushSnackbarWarning({message:`Synchronizing Users: This process will take some time...`})}, 3000);
			setTimeout(() => {
				notificationStore.pushSnackbarWarning({message:`Synchronizing Users: Running in the background...`});
				this.SynchUsersComplete();
			}, 3000);
		} catch(e) {
			notificationStore.pushSnackbarError({message:`Error synchronizing users ${e}`});
		}
	}
	async SynchUsersComplete() {
		this.syncLoading = false;
	}

	// userSyncLoading: boolean = false;
	// async syncUserById(userId: string): Promise<void>{
	// 	this.userSyncLoading = true;
	// 	try{
	// 		await userAccountApi.syncUserById(userId);
	// 		this.updateTable();
	// 	}catch(e){
	// 		console.error(e);
	// 	}finally{
	// 		this.userSyncLoading = false;
	// 	}
	// }

	duplicateLoading: boolean = false;
	duplicates: Array<UserAccountModel> = [];
	noDuplicatesFound: boolean = false;
	readyToMerge: boolean = false;
	primaryLoading: boolean = false;
	primaryAccount: UserAccountModel = undefined;
	mergedDuplicates: UserAccountModel = undefined;
	mergeData: Array<Record<string,any>> = [];
	showMergeDuplicates: boolean = false;
	mergeSkipLoading: boolean = false;
	detailsLoading: boolean = false;
	showAthleteDetail: boolean = false;
	showCoachDetail: boolean = false;
	showChildrenDetail: boolean = false;
	dateKeys: Array<string> = ['updated_at','deleted_at','last_login'];

	async onFindDuplicates(authId: string = undefined) {
		this.duplicateLoading = true;
		this.findDuplicates(authId);
	}
	async findDuplicates(authId: string = undefined) {
		if( this.IsEmpty(authId) ) this.duplicates = await userAccountApi.findDuplicates(this.IsNotEmpty(this.search)? this.search : '');
		else this.duplicates = await userAccountApi.findUserDuplicates(authId);
		
		this.findDuplicatesComplete();
	}
	async findDuplicatesComplete() {
		if( this.IsEmpty(this.duplicates) ) {
			this.noDuplicatesFound = true;
		} else {
			this.duplicates.forEach(d => {
				d.useInMerge = true;
				this.showAthleteDetail = false;
				this.showCoachDetail = false;
				this.showChildrenDetail = false;
			});
			let primaryId: string;
			let last_login: Date
			for( const d of this.duplicates ) {
				if( this.IsEmpty(primaryId) ) {
					primaryId = d.user_id;
					last_login = d.last_login;
					continue;
				}
				if( d.last_login > last_login ) {
					primaryId = d.user_id;
				}
			}
			this.onSelectPrimary(primaryId);
		}
		this.duplicateLoading = false;
	}
	get HasDuplicates(): boolean {
		if( this.duplicateLoading ) return false;
		return this.IsNotEmptyArray(this.duplicates);
	}
	get DuplicatesName(): string {
		for( const dup of this.duplicates ) {
			if( this.IsNotEmpty(dup.family_name) || this.IsNotEmpty(dup.given_name) ) {
				return this.FullName(dup.given_name, dup.family_name);
			}
		}
		return 'unknown'
	}
	private async PrepareMerge() {		
		this.readyToMerge = false;
		if( this.IsEmpty(this.primaryAccount) ) return;

		this.mergedDuplicates = new UserAccountModel().load(this.primaryAccount);
		if( this.IsEmpty(this.mergedDuplicates.logins_count) ) this.mergedDuplicates.logins_count = 0;
		this.duplicates.forEach( d => {
			if( this.IsDupPrimary(d.user_id) ) return;
			if( !d.useInMerge ) return;

			if( this.IsNotEmpty(d.logins_count) ) this.mergedDuplicates.logins_count += d.logins_count;

			for( const key of Object.keys(d) ) {
				if( this.IsNotEmpty(d[key]) ) {
					if( this.IsEmpty(this.mergedDuplicates[key]) ) {
						this.mergedDuplicates[key] = d[key];
					}
					if( this.dateKeys.includes(key)) {
						if( d[key] > this.mergedDuplicates[key] ) this.mergedDuplicates[key] = d[key];
					}
				}
			}
			this.mergedDuplicates.updated_at = new Date();
		})
		if( this.IsEmpty(this.mergedDuplicates.childProfileIds) ) this.mergedDuplicates.childProfileIds = [];
		this.duplicates.forEach( d => {
			if( d.user_id === this.primaryAccount.user_id ) return;
			if( !d.useInMerge ) return;
			if( this.IsEmpty(d.childProfileIds) ) return;
			d.childProfileIds.forEach(c => {
				if( !this.mergedDuplicates.childProfileIds.includes(c) ) this.mergedDuplicates.childProfileIds.push(c);
			})
		})
		this.mergeData = this.KeysToShow.map(k => {
			return {key: k, value: this.mergedDuplicates[k]}
		});

		this.readyToMerge = this.IsNotEmpty(this.primaryAccount);
	}
	onClearDuplicates() {
		this.cleanupMerge();
	}
	cleanupMerge() {
		this.duplicates = [];
		this.primaryAccount = undefined;
		this.mergedDuplicates = undefined;
		this.noDuplicatesFound = false;
		this.readyToMerge = false;
	}
	async onSkipMergeDuplicates() {
		this.mergeSkipLoading = true;
		await userAccountApi.mergeSkipAccounts(this.duplicates.map(d => d.user_id));
		this.mergeSkipLoading = false;
		this.cleanupMerge();
	}
	async onMergeDuplicates() {
		if( !this.readyToMerge ) {
			notificationStore.pushSnackbarError({message: 'Please select the primary account'}) 
			return;
		}
		this.PrepareMerge();
		this.showMergeDuplicates = true;
	}
	IsDupPrimary( userId: string ): boolean {
		if( this.IsEmpty(this.primaryAccount) ) return false;
		return userId === this.primaryAccount.user_id;
	}
	get DuplicatesCount(): number {
		return this.duplicates.length;
	}
	get MergeCount(): number {
		return this.duplicates.filter(d => d.useInMerge).length;
	}
	async MergeDuplicatesConfirm() {
		this.showMergeDuplicates = false;
		notificationStore.pushSnackbarWarning({message:`Merging User Accounts...`});

		// the first user_id must be that of the primary account
		const mergeIds = [this.primaryAccount.user_id];
		// add the selected duplicate
		mergeIds.push(...this.duplicates.map(d => d.user_id));
		userAccountApi.mergeAccounts(mergeIds);
		this.cleanupMerge();
	}
	async onSelectPrimary(userId: string) {
		this.primaryLoading = true;
		this.primaryAccount = undefined;

		this.duplicates.forEach(d => { d.primary = ( d.user_id === userId ) });
		const primary = this.duplicates.find(d => d.primary === true);
		if( this.IsNotEmpty(primary) ) primary.useInMerge = true;
		this.primaryAccount = new UserAccountModel().load(primary);
		await this.PrepareMerge();
		this.primaryLoading = false;
	}
	async onUseInMerge( userId: string ) {
		this.primaryLoading = true;
		await this.PrepareMerge();
		this.primaryLoading = false;
	}
	async onToggleAthleteDetail() {
		this.detailsLoading = true;
		this.showAthleteDetail = !this.showAthleteDetail;
		this.detailsLoading = false;
	}
	async onToggleCoachDetail() {
		this.detailsLoading = true;
		this.showCoachDetail = !this.showCoachDetail;
		this.detailsLoading = false;
	}
	async onToggleChildrenDetail() {
		this.detailsLoading = true;
		this.showChildrenDetail = !this.showChildrenDetail;
		this.detailsLoading = false;
	}
	AthleteProgressData(progress: TeamAthleteProgress): Array<Record<string,any>> {
		return this.KeysToShowAthleteProgress.map(k => {
			const key = AthleteProgressAbbreviations[k];
			const value: any = this.IsNotEmpty(progress)? progress[k] : undefined;
			return {key, value};
		});
	}
	CoachProgressData(coach: CoachProfileModel): Array<Record<string,any>> {
		return this.KeysToShowCoachProgress.map(k => {
			const key = AthleteProgressAbbreviations[k];
			const value: any = this.IsNotEmpty(coach)? coach[k] : undefined;
			return {key, value};
		});
	}

	get MergeHeaders(): Array<DataTableHeader<any>> {
		let headers: Array<DataTableHeader<any>> = [
			{ text: 'Key', value: 'key', sortable: false },
			{ text: 'Value', value: 'value', sortable: false },
		];
		return headers;
	}
	get KeysToShow(): Array<string> {
		return [
			'id',
			'user_id',
			'email',
			'name',
			'roles',
			'given_name',
			'family_name',
			'username',
			'phone_number',
			'nickname',
			'logins_count',
			'last_login',
			'created_at',
			'updated_at',
			'email_verified',
			'phone_verified',
			'user_metadata',
			'athleteProfileId',
			'childProfileIds',
			'coachProfileId',
			'app_metadata',
		]
	}
	get KeysToShowAthleteProgress(): Array<string> {
		return [
			'hasPhoto',
			'hasEmail',
			'hasPhone',
			'hasInsta',
			'hasTwitter',
			'hasBio',
			'hasDOB',
			'hasHeight',
			'hasWeight',
			'hasDominant',
			'hasLocation',
			'hasHighlights',
			'hasGradYear',
			'hasGPA',
			'hasSAT',
			'hasVerifiedAssessment',
			'mindsetArchetype',
			'hasScoutingReport',
			'commitment',
		]
	}
	get KeysToShowCoachProgress(): Array<string> {
		return [
			'email',
			'phoneNumber',
			'fromOrganization',
		]
	}
}
