
import { Component, Mixins } from 'vue-property-decorator';
import { PageState } from '@/models/PageState';
import { BAIconsMixin, DebounceMixin, LocalForageMixin, VuetifyMixin } from '@/mixins';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import Dialog from '@/components/ui/Dialog.vue';
import { BASchoolModel } from '../../models/baSchool/BASchoolModel';
import { CSVUploadMixin } from '@/mixins/CSVUploadMixin';
import { baSchoolApi } from '@/api/BASchoolApi';
import { notificationStore } from '@/store';

@Component({
	components: {
		CSVUploadDialog,
		Dialog,
		ExcelDataTable,
	}
})
export default class BASchoolsImport extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin, CSVUploadMixin, BAIconsMixin) {
	pageState = new PageState("Ready");
	sampleBASchool = [
		{
		ipedsId: "12345",
		opeId: "67890",
		name: "Sample University",
		phoneNum: "123-456-7890",
		website: "https://www.sampleuniversity.edu",
		schoolType: "Public",
		awardsOffered: "Bachelor's, Master's",
		campusSetting: "Urban",
		campusHousing: "Yes",
		studentPopulation: "10000",
		studentToFaculty: "15:1",
		links_admissions: "https://www.sampleuniversity.edu/admissions",
		links_apply: "https://www.sampleuniversity.edu/apply",
		links_financialAid: "https://www.sampleuniversity.edu/financialaid",
		links_netPriceCalc: "https://www.sampleuniversity.edu/netpricecalculator",
		links_vetTuition: "https://www.sampleuniversity.edu/veterantuition",
		links_disabilityServices: "https://www.sampleuniversity.edu/disabilityservices",
		links_athleticGrad: "https://www.sampleuniversity.edu/athleticgrad",
		tuitionYear_general: "22000",
		tuitionYear_inState: "20000",
		tuitionYear_outOfState: "18000",
		lodgings_onCampus: "6000",
		lodgings_offCampus: "8000",
		financialAid_general_recipients: "5000",
		financialAid_general_percentAwarded: "60%",
		financialAid_general_total: "$5,000,000",
		financialAid_general_average: "$10,000",
		grant_recipients: "3000",
		grant_percentAwarded: "30%",
		grant_total: "$3,000,000",
		grant_average: "$1,000",
		federalGrant_recipients: "1500",
		federalGrant_percentAwarded: "15%",
		federalGrant_total: "$1,500,000",
		federalGrant_average: "$1,000",
		pellGrant_recipients: "1000",
		pellGrant_percentAwarded: "10%",
		pellGrant_total: "$1,000,000",
		pellGrant_average: "$1,000",
		otherFederalGrant_recipients: "2000",
		otherFederalGrant_percentAwarded: "20%",
		otherFederalGrant_total: "$2,000,000",
		otherFederalGrant_average: "$1,000",
		stateGrant_recipients: "2000",
		stateGrant_percentAwarded: "20%",
		stateGrant_total: "$2,000,000",
		stateGrant_average: "$1,000",
		institutionalGrant_recipients: "2000",
		institutionalGrant_percentAwarded: "20%",
		institutionalGrant_total: "$2,000,000",
		institutionalGrant_average: "$1,000",
		studentLoan_recipients: "2000",
		studentLoan_percentAwarded: "20%",
		studentLoan_total: "$2,000,000",
		studentLoan_average: "$5,000",
		federalStudentLoan_recipients: "1000",
		federalStudentLoan_percentAwarded: "10%",
		federalStudentLoan_total: "$1,000,000",
		federalStudentLoan_average: "$5,000",
		otherStudentLoan_recipients: "1000",
		otherStudentLoan_percentAwarded: "10%",
		otherStudentLoan_total: "$1,000,000",
		otherStudentLoan_average: "$5,000",
		numApplicants_total: "8000",
		numApplicants_male: "4000",
		numApplicants_female: "4000",
		percentAdmitted_total: "40%",
		percentAdmitted_male: "45%",
		percentAdmitted_female: "35%",
		percentEnrolled_total: "50%",
		percentEnrolled_male: "55%",
		percentEnrolled_female: "45%",
		admissions_gpa: "true",
		admissions_rank: "false",
		admissions_record: "true",
		admissions_prep: "true",
		admissions_recommendation: "true",
		admissions_formalDemo: "false",
		admissions_experience: "true",
		admissions_essay: "true",
		admissions_legacy: "false",
		admissions_satact: "true",
		admissions_other: "false",
		admissions_englishProficiency: "false",
		admissions_satEnglish_percentile25: "500",
		admissions_satEnglish_percentile50: "550",
		admissions_satEnglish_percentile75: "600",
		admissions_satMath_percentile25: "520",
		admissions_satMath_percentile50: "580",
		admissions_satMath_percentile75: "630",
		admissions_actComposite_percentile25: "21",
		admissions_actComposite_percentile50: "24",
		admissions_actComposite_percentile75: "27",
		admissions_actEnglish_percentile25: "20",
		admissions_actEnglish_percentile50: "23",
		admissions_actEnglish_percentile75: "26",
		admissions_actMath_percentile25: "22",
		admissions_actMath_percentile50: "25",
		admissions_actMath_percentile75: "28",
		gradRate: "60%",
		popMajors: this.toArray('["Computer Science", "Business Administration", "Psychology"]'),
		},
	];
	
	toArray(s: string): string[] {
		if( !s || s.length == 0 ) return [];
		return s.replace(/\[|\]/g,'').split(',');
	}
	parseBASchools = (csvString: string): Partial<BASchoolModel>[] => {
		const rowJson = this.csv2json(csvString);
		const baSchools = rowJson.map(row => {
			const baSchool = {
				ipedsId: row.ipedsId,
				opeId: row.opeId,
				name: row.name,
				phoneNum: row.phoneNum,
				website: row.website,
				schoolType: row.schoolType,
				awardsOffered: row.awardsOffered,
				campusSetting: row.campusSetting,
				campusHousing: row.campusHousing,
				studentPopulation: row.studentPopulation,
				studentToFaculty: row.studentToFaculty,
				links_admissions: row.links_admissions,
				links_apply: row.links_apply,
				links_financialAid: row.links_financialAid,
				links_netPriceCalc: row.links_netPriceCalc,
				links_vetTuition: row.links_vetTuition,
				links_disabilityServices: row.links_disabilityServices,
				links_athleticGrad: row.links_athleticGrad,
				tuitionYear_general: row.tuitionYear_general,
				tuitionYear_inState: row.tuitionYear_inState,
				tuitionYear_outOfState: row.tuitionYear_outOfState,
				lodgings_onCampus: row.lodgings_onCampus,
				lodgings_offCampus: row.lodgings_offCampus,
				financialAid_general_recipients: row.financialAid_general_recipients,
				financialAid_general_percentAwarded: row.financialAid_general_percentAwarded,
				financialAid_general_total: row.financialAid_general_total,
				financialAid_general_average: row.financialAid_general_average,
				grant_recipients: row.grant_recipients,
				grant_percentAwarded: row.grant_percentAwarded,
				grant_total: row.grant_total,
				grant_average: row.grant_average,
				federalGrant_recipients: row.federalGrant_recipients,
				federalGrant_percentAwarded: row.federalGrant_percentAwarded,
				federalGrant_total: row.federalGrant_total,
				federalGrant_average: row.federalGrant_average,
				pellGrant_recipients: row.pellGrant_recipients,
				pellGrant_percentAwarded: row.pellGrant_percentAwarded,
				pellGrant_total: row.pellGrant_total,
				pellGrant_average: row.pellGrant_average,
				otherFederalGrant_recipients: row.otherFederalGrant_recipients,
				otherFederalGrant_percentAwarded: row.otherFederalGrant_percentAwarded,
				otherFederalGrant_total: row.otherFederalGrant_total,
				otherFederalGrant_average: row.otherFederalGrant_average,
				stateGrant_recipients: row.stateGrant_recipients,
				stateGrant_percentAwarded: row.stateGrant_percentAwarded,
				stateGrant_total: row.stateGrant_total,
				stateGrant_average: row.stateGrant_average,
				institutionalGrant_recipients: row.institutionalGrant_recipients,
				institutionalGrant_percentAwarded: row.institutionalGrant_percentAwarded,
				institutionalGrant_total: row.institutionalGrant_total,
				institutionalGrant_average: row.institutionalGrant_average,
				studentLoan_recipients: row.studentLoan_recipients,
				studentLoan_percentAwarded: row.studentLoan_percentAwarded,
				studentLoan_total: row.studentLoan_total,
				studentLoan_average: row.studentLoan_average,
				federalStudentLoan_recipients: row.federalStudentLoan_recipients,
				federalStudentLoan_percentAwarded: row.federalStudentLoan_percentAwarded,
				federalStudentLoan_total: row.federalStudentLoan_total,
				federalStudentLoan_average: row.federalStudentLoan_average,
				otherStudentLoan_recipients: row.otherStudentLoan_recipients,
				otherStudentLoan_percentAwarded: row.otherStudentLoan_percentAwarded,
				otherStudentLoan_total: row.otherStudentLoan_total,
				otherStudentLoan_average: row.otherStudentLoan_average,
				numApplicants_total: row.numApplicants_total,
				numApplicants_male: row.numApplicants_male,
				numApplicants_female: row.numApplicants_female,
				percentAdmitted_total: row.percentAdmitted_total,
				percentAdmitted_male: row.percentAdmitted_male,
				percentAdmitted_female: row.percentAdmitted_female,
				percentEnrolled_total: row.percentEnrolled_total,
				percentEnrolled_male: row.percentEnrolled_male,
				percentEnrolled_female: row.percentEnrolled_female,
				admissions_gpa: row.admissions_gpa,
				admissions_rank: row.admissions_rank,
				admissions_record: row.admissions_record,
				admissions_prep: row.admissions_prep,
				admissions_recommendation: row.admissions_recommendation,
				admissions_formalDemo: row.admissions_formalDemo,
				admissions_experience: row.admissions_experience,
				admissions_essay: row.admissions_essay,
				admissions_legacy: row.admissions_legacy,
				admissions_satact: row.admissions_satact,
				admissions_other: row.admissions_other,
				admissions_englishProficiency: row.admissions_englishProficiency,
				admissions_satEnglish_percentile25: row.admissions_satEnglish_percentile25,
				admissions_satEnglish_percentile50: row.admissions_satEnglish_percentile50,
				admissions_satEnglish_percentile75: row.admissions_satEnglish_percentile75,
				admissions_satMath_percentile25: row.admissions_satMath_percentile25,
				admissions_satMath_percentile50: row.admissions_satMath_percentile50,
				admissions_satMath_percentile75: row.admissions_satMath_percentile75,
				admissions_actComposite_percentile25: row.admissions_actComposite_percentile25,
				admissions_actComposite_percentile50: row.admissions_actComposite_percentile50,
				admissions_actComposite_percentile75: row.admissions_actComposite_percentile75,
				admissions_actEnglish_percentile25: row.admissions_actEnglish_percentile25,
				admissions_actEnglish_percentile50: row.admissions_actEnglish_percentile50,
				admissions_actEnglish_percentile75: row.admissions_actEnglish_percentile75,
				admissions_actMath_percentile25: row.admissions_actMath_percentile25,
				admissions_actMath_percentile50: row.admissions_actMath_percentile50,
				admissions_actMath_percentile75: row.admissions_actMath_percentile75,
				gradRate: row.gradRate,
				popMajors: this.toArray(row.popMajors),
			}
			return baSchool;
		});
		return baSchools;
	};

	showUploadBASchoolsDlg = false;
	showUploadPreview = false;

	unconfirmedBASchools: Array<BASchoolModel> = [];
	get BASchoolsRowPreview(): Array<any>{
		return this.unconfirmedBASchools;
	}

	onBASchoolsLoaded(data: Array<BASchoolModel>): void {
		this.unconfirmedBASchools = data;
		this.showUploadPreview = true
	}
	startOver(): void{
		this.importingBASchools = false;
		this.showUploadPreview = false;
		this.unconfirmedBASchools = [];
	}

	importingBASchools: boolean = false;
	get isImporting(): boolean {
		return this.importingBASchools;
	}
	baSchoolsUpdated: number = 0;
	BASchoolUpdateError: string;
	async confirmBASchools() {
		this.importingBASchools = true;

		this.$emit('submit', {
			baSchools: this.unconfirmedBASchools,
		});

		this.showUploadPreview = false;
		// TODO: Add update notifications
		notificationStore.pushSnackbarWarning({message: `Updating ${this.unconfirmedBASchools.length} schools`})
		await baSchoolApi.updateBASchools(this.unconfirmedBASchools);
		this.$emit('updated')
		this.startOver();
	}
	abort: boolean = false;
	onAbortUpload() {
		this.abort = true;	
		this.startOver();
	}
	get BASchoolsCount(): number{
		return this.unconfirmedBASchools.length;
	}
}
