
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { DebounceMixin, LocalForageMixin, VuetifyMixin, BAIconsMixin } from '@/mixins';
import { ContactInfo } from '@/../types/interfaces';
import CSVUploadDialog from '@/components/teams/CSVUploadDialog.vue';
import ExcelDataTable from '@/components/ui/ExcelDataTable.vue';
import Dialog from '@/components/ui/Dialog.vue';
import AdminAutoCompleteInput from '@/components/forms/AdminAutoCompleteInput.vue';
import { PageState } from '@/models/PageState';
import { AthleteAssessmentDataModel } from '@/models';
import { formatDateHyphensYYYYMMDD } from '@/helpers';
import { TeamGenderValues } from '@/../types/enums';
import { AssessmentEntry } from '@/../types/interfaces/PyhsicalAssessmentDataFormValue';
import { OrganizationModel } from '@/models/organization/OrganizationModel';
import { TeamModel } from '@/models/team';
import { OrganizationMemberImportData } from '@/../types/interfaces/OrganizationMemberImportData';
import { CSVUploadMixin } from '../../mixins/CSVUploadMixin';

const IMPORT_COLUMNS_MAP = {
	"organizationId": true,
	"teamId": true,
	"athleteShortId": true,
	"assessmentDate": true,
	"firstName": true,
	"lastName": true,
	"email": true,
	"playingLevel": true,
	"competitiveLevel": true,
	"gender": true,
	"dominantFoot": true,
	"playingPosition": true,
	"dateOfBirth": true,
	"ageYear": true,
	"ageMonthRemainder": true,
	"ageMonthDecimal": true,
	"age": true,
	"mass": true,
	"standingHeight": true,
	"trueSittingHeight": true,
	"sittingHeightWithBox": true,
	"boxHeight": true,
	"legLength": true,
	"heightAgain": true,
	"massAgain": true,
	"sittingHeightAgain": true,
	"legTrunk": true,
	"ageLeg": true,
	"ageSittingHeight": true,
	"ageMass": true,
	"massHeightRatio": true,
	"bodyMassIndex": true,
	"maturityOffset": true,
	"ageOfPeakHeightVelocity": true,
	"developmentalCategory": true,
	"counterMovementJumpHeight": true,
	"power": true,
	"dropJumpContactTime": true,
	"dropJumpHeight": true,
	"reactiveStrengthIndex": true,
	"tenMeterSprint": true,
	"acceleration": true,
	"twentyMeterSprint": true,
	"thirtyFiveMeterSprint": true,
	"twentyToThirtyFiveMeterSplit": true,
	"speed": true,
	"yoyoIntermittentRecoveryTestStage": true,
	"yoyoIntermittentRecoveryTestDistance": true,
	"maximalAerobicVelocity": true,
};

export type NameToIdMap = { organizations: Record<string,string>, teams: Record<string,string> };

@Component({
	components: {
		AdminAutoCompleteInput,
		CSVUploadDialog,
		Dialog,
		ExcelDataTable,
	}
})
export default class VerifiedAssessmentImportV4 extends Mixins(DebounceMixin, LocalForageMixin, VuetifyMixin, CSVUploadMixin, BAIconsMixin){
	TeamGenderValues = TeamGenderValues;
	showInfo: boolean = false;
	showAll: boolean = false;

	pageState = new PageState("Ready");

	nameToIdMap: NameToIdMap = { organizations: {}, teams: {} };
	notify = false;
	fixDuplicates = true;
	ignoreLinks = false;

	get OrgNames(): string[]{
		const names = new Set<string>();
		this.unconfirmedAssessments
			.filter(a => !!a.link.organizationId)
			.forEach(a => names.add(a.link.organizationId));
		return Array.from(names);
	}
	get OrgNamesCount(): number{
		return this.OrgNames.length;
	}
	get TeamNames(): string[]{
		const names = new Set<string>();
		this.unconfirmedAssessments
			.filter(a => !!a.link.teamIds)
			.map(a => a.link.teamIds)
			.reduce((a,b) => [...a, ...b], [])
			.filter(a => !!a)
			.forEach(teamName => names.add(teamName));
		return Array.from(names);
	}
	get TeamNamesCount(): number{
		return this.TeamNames.length
	}

	onOrgSelected(name: string, org: OrganizationModel): void{
		Vue.set(this.nameToIdMap.organizations, name, org.id);
	}
	clearOrg(name: string): void{
		Vue.set(this.nameToIdMap.organizations, name, undefined);
	}
	onTeamSelected(name: string, team: TeamModel): void{
		Vue.set(this.nameToIdMap.teams, name, team.id);
	}
	clearTeam(name: string): void{
		Vue.set(this.nameToIdMap.teams, name, undefined);
	}

	sampleCSVData = [
		{
			organizationId: "Best Athletes SC",
			teamId: "Best Athletes B2006",
			athleteShortId: "B0haQ8",
			assessmentDate: "2022-01-01",
			firstName: "Alberto",
			lastName: "Aarjik",
			email: "aarjik001@bestathletes.co",
			playingLevel: "U17",
			competitiveLevel: "3",
			gender: "male",
			dominantFoot: "right",
			playingPosition: "Midfielder",
			dateOfBirth: "2006-06-06",
			ageYear: "16",
			ageMonthRemainder: "6",
			ageMonthDecimal: "0.5",
			age: "16.5",
			mass: "66",
			standingHeight: "172.5",
			trueSittingHeight: "89.9",
			sittingHeightWithBox: "131.9",
			boxHeight: "42",
			legLength: "40.6",
			heightAgain: "172.5",
			massAgain: "66",
			sittingHeightAgain: "89.9",
			legTrunk: "7425.74",
			ageLeg: "1369.8",
			ageSittingHeight: "1490.8",
			ageMass: "1094.5",
			massHeightRatio: "38.26",
			bodyMassIndex: "22.18",
			maturityOffset: "2.13",
			ageOfPeakHeightVelocity: "14.45",
			developmentalCategory: "",
			counterMovementJumpHeight: "34.3",
			power: "3504.99",
			dropJumpContactTime: "0.339",
			dropJumpHeight: "33.1",
			reactiveStrengthIndex: "1.0",
			tenMeterSprint: "1.99",
			acceleration: "5.0",
			twentyMeterSprint: "3.28",
			thirtyFiveMeterSprint: "4.62",
			twentyToThirtyFiveMeterSplit: "1.34",
			speed: "11.2",
			yoyoIntermittentRecoveryTestStage: "17.3",
			yoyoIntermittentRecoveryTestDistance: "1520",
			maximalAerobicVelocity: "16",
		},
	];
	
	parseVerifiedAssessments = (csvString: string): AssessmentEntry[] => {
		/**
		const rows = csvString.split('\n')
			.map(line => line.replaceAll('\r', '')) // Remove CR characher if using CRLF lines
			.filter(line => line.length > 0)
			.map(line => line.split(',')).slice(1);
		const keys = this.CSVDataTableHeaders.slice(1).map(h => h.value.trim());
		const rowJson: Record<keyof typeof IMPORT_COLUMNS_MAP, string>[] = rows.map(row => {
			return keys.reduce<Record<keyof typeof IMPORT_COLUMNS_MAP, string>>((json, value, index) => {
				if(row[index] === undefined || row[index] === '#DIV/0!'){
					// Missing or invalid value in cell
					json[value] = undefined
				}else if(["email","dateOfBirth","assessmentDate"].includes(value)){
					// For emails, only trim whitespace
					json[value] = row[index].trim();
				}else{
					// For all other values, remove any non-alphanumeric chars '" "10"' => 10
					json[value] = row[index].replace(/[^A-Za-z0-9-.]/g, '').trim();
				}
				return json;
			}, {} as any);
		});
		 */
		const rowJson = this.csv2json(csvString);
		const assessments = rowJson.map(row => {
			const contact: ContactInfo = {
				firstName: row.firstName,
				lastName: row.lastName,
				email: row.email,
			};
			const link: AssessmentEntry['link'] = {
				organizationId: row.organizationId,
				teamIds: [row.teamId],
				athleteShortId: row.athleteShortId ?? "",
			};
			const data: AthleteAssessmentDataModel = {
				...row as any,
			};
			return { contact, data, link };
		});
		return assessments;
	};

	showUploadDialog = false;
	CSVUploadPreviewModalVisible = false;

	unconfirmedAssessments: Array<AssessmentEntry> = [];
	get AssessmentRowPreview(): Array<any>{
		return this.unconfirmedAssessments.map(x => ({
			...x.link,
			...x.contact,
			...x.data,
		}));
	}

	/**
	 * Returns Org Member import data which can be uploaded on step 3 of the organization create page to automatically generate teams
	 */
	private getOrgMemberImportData({ orgName = null, teamName = null }: {orgName?: string | null, teamName?: string | null}): OrganizationMemberImportData[]{
		const assessmentEntryToOrgMember = (a: AssessmentEntry): OrganizationMemberImportData => ({
			firstName: a.contact.firstName,
			lastName: a.contact.lastName,
			email: a.contact.email,
			team: a.link.teamIds[0],
			gender: a.data.gender,
			role: "Player",
			seasonFormat: "Year",
		});
		if(teamName !== null){
			return this.unconfirmedAssessments
				.filter(a => a.link.teamIds.includes(teamName))
				.map(assessmentEntryToOrgMember);
		}else if(orgName !== null){
			return this.unconfirmedAssessments
				.filter(a => a.link.organizationId.includes(orgName))
				.map(assessmentEntryToOrgMember);
		}else{
			return this.unconfirmedAssessments.map(assessmentEntryToOrgMember);
		}
	}
	downloadOrgMemberImportData({ orgName = null, teamName = null }: {orgName?: string | null, teamName?: string | null}): void{
		const csv = this.getOrgMemberImportData({ orgName, teamName })
			.filter(row => row)
			.map(row => {
				const r = [];
				r.push(row.firstName);
				r.push(row.lastName);
				r.push(row.team);
				r.push(row.gender);
				r.push(row.role);
				r.push(row.email);
				return r.join(',');
			}).join('\n');
		const blob = new Blob([csv]);
		let dl = document.createElement('a');
		dl.href = window.URL.createObjectURL(blob);
		dl.download = `assessment-organization-member-import-${teamName ? teamName : orgName ? orgName : `${formatDateHyphensYYYYMMDD(new Date)}`}.csv`;
		dl.click();
	}

	onAssessmentsLoaded(data: Array<AssessmentEntry>): void {
		this.unconfirmedAssessments = data;
		this.CSVUploadPreviewModalVisible = true
	}
	startOver(): void{
		this.CSVUploadPreviewModalVisible = false;
		this.unconfirmedAssessments = [];
	}
	confirmCSVData(): void {
		this.$emit('submit', {
			assessments: this.useLinkedIds(this.unconfirmedAssessments),
			notify: this.notify,
			fixDuplicates: this.fixDuplicates
		});
		this.startOver();
	}
	get AssessmentCount(): number{
		return this.unconfirmedAssessments.length;
	}
	get EmailCount(): number{
		return this.unconfirmedAssessments.filter(a => !!a.contact.email).length;
	}
	useLinkedIds(assessments: AssessmentEntry[]): AssessmentEntry[]{
		return assessments.map(a => {
			const { organizationId, teamIds } = a.link;
			const [teamId] = teamIds ?? [];
			if(organizationId && this.nameToIdMap.organizations[organizationId]){
				a.data.organizationId = this.nameToIdMap.organizations[organizationId];
				a.link.organizationId = this.nameToIdMap.organizations[organizationId];
			}else{
				a.data.organizationId = undefined;
				a.link.organizationId = undefined;
			}
			if(teamId && this.nameToIdMap.teams[teamId]){
				a.link.teamIds = [this.nameToIdMap.teams[teamId]];
			}else{
				a.link.teamIds = undefined;
			}
			return a;
		});
	}

}
